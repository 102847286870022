"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNeighborNodeIds = void 0;
/**
 * 获取邻居节点
 * @param nodeId - 节点 ID
 * @param edges - 边数据
 * @param direction - 边的方向
 * @returns 邻居节点 ID
 */
const getNeighborNodeIds = (nodeId, edges, direction) => {
    const getSuccessorNodeIds = (reverse = false) => {
        const [source, target] = reverse ? ['target', 'source'] : ['source', 'target'];
        return edges.filter((edge) => edge[source] === nodeId).map((edge) => edge[target]);
    };
    if (direction === 'out')
        return getSuccessorNodeIds();
    if (direction === 'in')
        return getSuccessorNodeIds(true);
    return getSuccessorNodeIds().concat(getSuccessorNodeIds(true));
};
exports.getNeighborNodeIds = getNeighborNodeIds;
