(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-dom"), require("lodash"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["react-dom", "lodash", "react"], factory);
	else if(typeof exports === 'object')
		exports["Charts"] = factory(require("react-dom"), require("lodash"), require("react"));
	else
		root["Charts"] = factory(root["ReactDOM"], root["_"], root["React"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__6003__, __WEBPACK_EXTERNAL_MODULE__9276__, __WEBPACK_EXTERNAL_MODULE__5442__) => {
return 