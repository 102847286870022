"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMMON_OPTIONS = void 0;
exports.COMMON_OPTIONS = {
    behaviors: [
        {
            key: 'zoom-canvas',
            type: 'zoom-canvas',
        },
        {
            key: 'drag-canvas',
            type: 'drag-canvas',
        },
    ],
};
