import React from 'react';
import styled, { css } from 'styled-components';
const StyledWrapper = styled.div `
  height: inherit;
  width: inherit;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  border: none;
  background-color: #fff;
  box-sizing: content-box;

  ${(props) => props.$isActive &&
    css `
      transform: translate(-3px, -3px);
      border: 2px solid #1783ff;
    `}

  .org-chart-node-line {
    width: 100%;
    height: 6px;
    background-color: ${(props) => props.$color};
    border-radius: 8px 8px 0 0;
  }

  .org-chart-node-content {
    height: calc(100% - 6px);
    margin: 0 16px 3px;
    display: flex;
    align-items: center;

    &-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;
      background-color: ${(props) => props.$color};
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
      color: #fff;
    }

    &-detail {
      width: calc(100% - 56px);
    }

    &-name {
      color: #242424;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-post {
      color: #616161;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const OrganizationChartNode = (props) => {
    const { name, position, status = 'online', isActive, className, style } = props;
    const colorMap = {
        online: '#1783FF',
        busy: '#00C9C9',
        offline: '#F08F56',
    };
    return (React.createElement(StyledWrapper, { "$color": colorMap[status], "$isActive": isActive, className: className, style: style },
        React.createElement("div", { className: "org-chart-node-line" }),
        React.createElement("div", { className: "org-chart-node-content" },
            React.createElement("div", { className: "org-chart-node-content-avatar" }, name.slice(0, 1)),
            React.createElement("div", { className: "org-chart-node-content-detail" },
                React.createElement("div", { className: "org-chart-node-content-name" }, name),
                position && React.createElement("div", { className: "org-chart-node-content-post" }, position)))));
};
