export const COMMON_OPTIONS = {
    autoResize: true,
    behaviors: [
        {
            key: 'zoom-canvas',
            type: 'zoom-canvas',
        },
        {
            key: 'drag-canvas',
            type: 'drag-canvas',
        },
    ],
};
