"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranslateReactNodeOrigin = void 0;
const g6_1 = require("@antv/g6");
class TranslateReactNodeOrigin extends g6_1.BaseTransform {
    afterLayout() {
        const { graph, model, element } = this.context;
        graph.getNodeData().forEach((datum) => {
            const nodeId = (0, g6_1.idOf)(datum);
            const node = element.getElement(nodeId);
            if (!node)
                return;
            const style = graph.getElementRenderStyle(nodeId);
            const { size } = style;
            model.updateNodeData([
                {
                    id: nodeId,
                    // HTML 元素的默认原点位置在左上角，而 G6 的默认原点位置在中心，所以需要调整 dx 和 dy
                    style: {
                        dx: -size[0] / 2,
                        dy: -size[1] / 2,
                    },
                },
            ]);
        });
        graph.draw();
    }
}
exports.TranslateReactNodeOrigin = TranslateReactNodeOrigin;
