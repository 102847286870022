var ctx;
/**
 * 获取 canvas context
 */
export var getCanvasContext = function () {
    if (!ctx) {
        ctx = document.createElement('canvas').getContext('2d');
    }
    return ctx;
};
